
/* Form validation */

(function () {
    'use strict';

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation');


    /*
     * Check if (password) field matches other field
     */
    const repeatFields = document.querySelectorAll('[data-match]');
    repeatFields.forEach(function(field) {
        field.addEventListener('blur', function() {
            fieldMatchValidates(field);
        });
    });

    function fieldMatchValidates(field) {
        const matchField = document.getElementById(field.getAttribute('data-match'));
        field.setCustomValidity('');
        if (field.value !== matchField.value) {
            field.setCustomValidity("Fields don't match.");
            return false;
        }
        return true;
    }

    function repeatPasswordIsValid(form) {
        const repeatFields = form.querySelectorAll('[data-match]');
        let valid = true;
        repeatFields.forEach(function(field) {
            if (!fieldMatchValidates(field)) {
                valid = false;
            }
        });
        return valid;
    }


    /*
     * Check if password is strong enough
     */
    const passwordFields = document.querySelectorAll('input.pwstrength');
    passwordFields.forEach(function(field) {
        field.addEventListener('blur', function() {
            passwordStrengthValidates(field);
        });
    });

    function passwordStrengthValidates(field) {
        const score = parseInt(field.getAttribute('data-pwstrength-score'));
        field.setCustomValidity('');
        if (score < 40) {
            field.setCustomValidity('Please use a strong password.');
            return false;
        }
        return true;
    }

    function passwordIsValid(form) {
        const passwordFields = form.querySelectorAll('[data-pwstrength-score]');
        let valid = true;
        passwordFields.forEach(function(field) {
            if (!passwordStrengthValidates(field)) {
                valid = false;
            }
        });
        return valid;
    }


    /*
     * Check if checkbox group has at least one check
     */
    forms.forEach(function (form) {
        const checkboxes = form.querySelectorAll('[data-checkbox-group]');
        checkboxes.forEach(function(field) {
            field.addEventListener('click', function() {
                checkboxValidates(field, form);
            });
        });
    });

    function checkboxValidates(field, form) {
        const groupName = field.dataset.checkboxGroup;
        const groupFields = form.querySelectorAll('[data-checkbox-group="' + groupName + '"]');
        console.log(groupFields); //document.getElementById(field.getAttribute('data-match'));
        var checkedOne = Array.prototype.slice.call(groupFields).some(x => x.checked);
        console.log(checkedOne);
        field.setCustomValidity('');
        if (!checkedOne) {
            field.setCustomValidity("Fields don't match.");
            return false;
        }
        return true;
    }

    function checkboxGroupsAreValid(form) {
        const checkboxes = form.querySelectorAll('[data-checkbox-group]');
        let valid = true;
        checkboxes.forEach(function(field) {
            if (!checkboxValidates(field, form)) {
                valid = false;
            }
        });
        return valid;
    }


    // Loop over forms and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!repeatPasswordIsValid(event.target) || !passwordIsValid(event.target) || !checkboxGroupsAreValid(event.target) || !form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                form.classList.add('was-validated');
            }, false);
        });
})();
