
function HarrassowitzSolrSearcher(baseUrl, pageSize, onSearchResult) {
	this.searchInput = '';
	this.start = 0;
	this.count = 10;

	var that = this;

	var handleSolrResult = function(data){
		var firstIndex = data.response.start + 1;
		var lastIndex = data.response.start + data.response.docs.length;
		var numFound = data.response.numFound;
		var currentPage = parseInt(that.start / pageSize) + 1;

		var docs = data.response.docs;
		var results = [];

		$(docs).each(function(index, doc) {
			var abstract = doc.content;

			if (abstract.length > 100) {
				abstract = abstract.substring(0, 100) + '...';
			}

			if (data.highlighting &&
				data.highlighting[doc.id] &&
				data.highlighting[doc.id].content &&
				data.highlighting[doc.id].content[0])
			{
				abstract = data.highlighting[doc.id].content[0];
			}

			results.push({
				title: doc.title,
				abstract: abstract,
				url: doc.url
			});
		});

		onSearchResult(firstIndex, lastIndex, numFound, currentPage, pageSize, results);
	};

	var executeSolrSearch = function() {
		$.ajax({
			url: baseUrl,
			data: {
				wt:'json',
				q: that.searchInput,
				hl: true,
				start: that.start,
				count: that.count,
				'hl.fl': 'content',
				'hl.simple.pre': '<b>',
				'hl.simple.post': '</b>'
			},
			dataType: 'jsonp',
			jsonp: 'json.wrf',
			success: handleSolrResult
		});
	};

	this.search = function(text) {
		that.searchInput = text;
		that.start = 0;
		executeSolrSearch();
	};

	this.setStartIndex = function(startIndex) {
		that.start = startIndex;
		executeSolrSearch();
	};
}




export default function initSearch() {

	var searcher, searchTemplate, searchQuery;

	var maxPages = 10;
	var maxPagesMobile = 5;

	var onClickSearchPagination = function(event) {
		searcher.setStartIndex(event.data.startIndex);
		return false;
	};


	var onSearchResult = function(firstIndex, lastIndex, numFound, currentPage, pageSize, results) {

		var $searchResultsHTML = $(searchTemplate);
		$('.header-image').remove();
		$('main > *').remove();
		$('main').append($searchResultsHTML);

		$('.search-query').text(searchQuery);

		$.each(results, function(index, result) {
			var $resultHTML = $('<li></li>');
			$resultHTML.append($('<div><a href="' + result.url + '" class="search-results--title">' + result.title + '</a></div>'));
			$resultHTML.append($('<p>' + result.abstract + '</p>'));
			$resultHTML.append($('<div><a href="' + result.url + '" class="search-results--link">' + result.url + '</a></div>'));

			$('ul.search-results').append($resultHTML);
		});


		$pagination = $('<div class="row text-center"></div>').append('<nav aria-label="Page navigation"><ul class="pagination"></ul></nav>');

		var maxPageCount = isMobile() ? maxPagesMobile : maxPages;
		pages = (numFound / pageSize < maxPageCount) ? numFound / pageSize : maxPageCount;

		for (var i = 1; i <= pages; i++) {
			var $pageLink = $('<li class="page-item"><a class="page-link" href="#">' + i + '</a></li>');
			if (currentPage === i) {
				$pageLink.find('a').addClass('active');
			}
			$pageLink.find('a').on('click', {startIndex: i * pageSize - pageSize}, onClickSearchPagination);
			$pagination.find('.pagination').append($pageLink);
		}

		$('main').append($pagination);

	};


	searcher = new HarrassowitzSolrSearcher(
		'/search',
		10,
		onSearchResult
	);


	$('.header-search--toggle').on('click', function() {
		$('.header-search--mobile').slideToggle();
		return false;
	});


	$('.header-search form, .header-search--mobile form').submit(function(event) {
		var $form = $(this);

		if (searchTemplate === undefined) {

			$.ajax({
				async: false,
				cache: true,
				url: '/includes/search-template.html',
				success: function(data) {
					searchTemplate = data;
				}
			});
		}

		searchQuery = $form.find('[name=query]').val();
		searcher.search(searchQuery);

		event.preventDefault();
	});
}
