import './jquery-global';
import 'bootstrap';
import initSearch from './search';
import './validator';
import '../../node_modules/pwstrength-bootstrap/dist/pwstrength-bootstrap.min.js';


jQuery(document).ready(function($) {
    $('form .form-submit').on('click', function() {
        var $form = $(this).closest('form');
        $form.submit();
    });

    $('.header-login--toggle').on('click', function() {
        $('.header-login').not($(this).siblings('.header-login')).slideUp();
        $(this).siblings('.header-login').slideToggle();
        return false;
    });

    initHome();
    initSearch();
    initNews();
    initPasswordStrength();


    function initHome() {
        if (!isMobile()) {
            var $teaserBoxes = $('.teaser-box--home');
            var maxHeight = 0;
            $teaserBoxes.each(function() {
                maxHeight = maxHeight > $(this).find('p').height() ? maxHeight : $(this).find('p').height();
            });

            $teaserBoxes.find('p').css({'min-height': maxHeight + 'px'});
        }
    }


    function isMobile() {
        return $(window).width() < 544;
    }


    function initPasswordStrength() {
        if ($('.pwd-container').length === 0) {
            return;
        }

        /* Init password strength indicator */
        var options = {
            ui: {
                container: '.pwd-container',
                viewports: {
                    progress: '.pwd-strength-progress'
                },
                showVerdicts: false,
            },
            common: {
                minChar: 8,
                onKeyUp: function (evt, data) {
                    $(evt.target).attr('data-pwstrength-score', data.score);
                },
            },
            rules: {
                raisePower: 1.5,
                activated: {
                    wordInvalidChar: false,
                }
            }
        };
        $('input.pwstrength').pwstrength(options);
    }


    function moveChildren(source, target, offset) {
        $(source).children().each(function(index, child) {
            if (index > offset) {
                $(this).appendTo(target);
            }
        });
    }


    function initNews() {
        moveChildren('#all-news', '#all-news-more', 4);
        moveChildren('#all-special-announcements', '#all-special-announcements-more', 4);
        moveChildren('#all-press-realeases', '#all-press-realeases-more', 4);
        moveChildren('#all-did-you-know', '#all-did-you-know-more', 4);

        $('.collapse-news').on('show.bs.collapse', function () {
            var button = $('button[aria-controls=' + this.id + ']');
            button.text('Show less');
        });

        $('.collapse-news').on('hide.bs.collapse', function () {
            var button = $('button[aria-controls=' + this.id + ']');
            button.text('Show more');
        });
    }

});
